import { getRequest } from "../apiRequest";
import { apiRoutes } from "../Constants/ApiRoutes";

export const getArticles = (id, pathParams) => {
  if (!id) {
    return;
  }
  const url = `${apiRoutes.categories}/${id}/${apiRoutes.getArticlesInCategory}`;
  return getRequest(url, null, pathParams);
};

export const getParticularArticle = (id, pathParams) => {
  if (!id) {
    return;
  }
  const url = `${apiRoutes.getParticulatArticle}/${id}`;
  return getRequest(url, null, pathParams);
};

export const articleSearch = (searchTerm, pathParams, signal) => {
  if (!searchTerm) {
    return;
  }
  const url = `${apiRoutes.getParticulatArticle}/${apiRoutes.articleSearch}`;
  return getRequest(url, null, pathParams, undefined, undefined, signal ).then( response => {
    // handling signal abort here as Bifrost interface does not expose signal param.
    if( signal.aborted )throw new Error("signal aborted");
    else return response;
  });
};
