import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";

import App from "./App";
import { setAppConfig } from "./Bifrost/api";
import reportWebVitals from "./reportWebVitals";
import { getEnv } from "./utils";
import { Base_Url } from "./Constants/ApiRoutes";

const container = document.getElementById("root");

const root = createRoot(container);
const { app_env, tier } = getEnv();
setAppConfig(Base_Url, app_env, tier);
root.render(<App tab="home" />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
