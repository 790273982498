import axios from "axios";
import { HiverKbBifrost } from "./Bifrost";
import { API_METHODS } from "./Constants/Bifrost";

import { getState } from "./Constants/LocalStorage";
import { buildParamString } from "./utils";

import { Base_Url, Admin_Base_URL, apiRoutes, Hiver_New_Auth_Base_Url, Hiver_New_Auth_Public_Base_Url } from "./Constants/ApiRoutes";

// Set config defaults when creating the instance
const axiosInstance = axios.create({
  credentials: "include",
  withCredentials: true,
  baseURL: Base_Url + "v1/",
});

axiosInstance.interceptors.request.use(
  function (config) {
    const isGetUserDetailsViaKb = config.url === apiRoutes.getUserDetailsViaKb;
    const token = getState("authToken-website");
    if (token && isGetUserDetailsViaKb) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
      }

      if (error.response.status === 409) {
        console.log(error.response.data.error.message);
      }

      if (error.response.status === 500) {
        console.log(error.response.data.error.err.errors[0].message);
      }
    }

    if ((process.env.REACT_APP_KB_ENV === "dev" && error.request.status === 0)) {
      error.response = { status: 401 };
    }

    return Promise.reject(error);
  }
);

const axiosPublicInstance = axios.create({
  baseURL: Hiver_New_Auth_Public_Base_Url
});



export const getRequest = (
  url,
  payload,
  pathParams = {},
  isInternal = window?.grexit?.isInternal ?? false,
  isNewAuth = window?.grexit?.isNewAuthFlow ?? false
) => {
  const paramString = buildParamString(pathParams);
  if (!isNewAuth && false) { // TODO: marked for removal later.
    let config = {
      params: {
        ...payload,
      },
    };
    return axiosInstance.get(url + paramString, config);
  } else {
    if( isInternal ){
      return HiverKbBifrost({
        url: url + paramString,
        method: API_METHODS.GET,
        data: payload,
        root_url: isInternal ? Hiver_New_Auth_Base_Url : Hiver_New_Auth_Public_Base_Url
      });
    }
    else {
      return axiosPublicInstance.get( url, { params: { ...payload, ...pathParams } } );
    }
  }
};

export const postRequest = (
  url,
  payload,
  params,
  isInternal = window?.grexit?.isInternal ?? false,
  isNewAuth = window?.grexit?.isNewAuthFlow ?? false
) => {
  if (!isNewAuth && false ) { // TODO: marked for removal later.
    return axiosInstance.post(url, payload, { params: params });
  } else {
    if( isInternal ){
      return HiverKbBifrost({
        url,
        method: API_METHODS.POST,
        data: payload,
        params,
        root_url: isInternal ? Hiver_New_Auth_Base_Url : Hiver_New_Auth_Public_Base_Url
      });
    }
    else {
      return axiosPublicInstance.post( url, payload, { params: params } );
    }
  }
};

export const putRequest = (
  url,
  payload,
  params,
  isInternal = window?.grexit?.isInternal ?? false,
  isNewAuth = window?.grexit?.isNewAuthFlow ?? false
) => {
  if (!isNewAuth && false ) { // TODO: marked for removal later.
    return axiosInstance.put(url, payload, { params: params });
  } else {
    if( isInternal ){ 
      return HiverKbBifrost({
        url,
        method: API_METHODS.PUT,
        data: payload,
        params,
        root_url: isInternal ? Hiver_New_Auth_Base_Url : Hiver_New_Auth_Public_Base_Url
      });
    }
    else{
      return axiosPublicInstance.put(url, payload, { params: params });
    }
  }
};

export const deleteRequest = (
  url,
  bodyPayload,
  urlPayload,
  isInternal = window?.grexit?.isInternal ?? false,
  isNewAuth = window?.grexit?.isNewAuthFlow ?? false
) => {
  let config = {
    params: {
      ...urlPayload,
    },
    data: Array.isArray(bodyPayload) ? [...bodyPayload] : { ...bodyPayload },
  };
  if (!isNewAuth && false ) { // TODO: marked for removal later.
    return axiosInstance.delete(url, config);
  } else {
    if( isInternal ){ 
      return HiverKbBifrost({
        url,
        method: API_METHODS.DELETE,
        data: config.data,
        params: config.params,
        root_url: Hiver_New_Auth_Base_Url
      });
    }
    else {
      return axiosPublicInstance.delete(url, config);
    }
  }
};

export const getToken = (payload) => {
  let config = {
    baseURL: Admin_Base_URL,
    params: {
      ...payload,
    },
  };
  // get-token as of now is v2api and is completely old auth as of now.
  // future login mechanism might get rid of this. so always defaulting to old auth as of now
  return axiosInstance.get(apiRoutes.hiverGetToken, config);
  // if (!isNewAuth) {
  // } else {
  //   return HiverKbBifrost({
  //     url: apiRoutes.hiverGetToken,
  //     method: API_METHODS.GET,
  //     params: config.params,
  //     root_url: config.baseURL,
  //     noToken: false,
  //   });
  // }
};

export const getUserDetails = () => {
  return getRequest(apiRoutes.getUserDetailsViaKb);
};

export const getAvailableFeatures = (pathParams = {}) => {
  const paramString = buildParamString(pathParams);
  return getRequest(apiRoutes.availableFeatures + paramString)
};
