import { useState, useEffect, useContext } from "react";
import { setAppUserId, setAppUgId } from "../../Bifrost/api";

import { AutherContext, ToastContext, KBContext } from "../index";
import { setState } from "../../Constants/LocalStorage";
import { open_popup, onOAuthSuccess, getGoogleAuthUrl } from "../../pages/Login/utils";
import { getToken, getUserDetails } from "../../apiRequest";
import { DASHBOARD_ALLOWED_ROLES, INTERNAL_KB_ALLOWED_ROLES } from "../../Constants/KBContext.constants";
import { getUserDetailsviaV2ProfileMe } from "../../Service/LoginServices";

export default function AutherProvider(props) {
    const { setNoNet, setShowToast } = useContext(ToastContext);
    const { setHiddenState, setShowDashboardFooter, data, data: { isInternal = false }} = useContext(KBContext);
    const [isProfileMeDone, setIsProfileMeDone] = useState(false);
    const [showError, setShowError] = useState(false);
    const [email, setEmail] = useState("");
    const [refetchToken, setRefetchToken] = useState(true);
    const [authToken, setAuthToken] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [userSession, setUserSession] = useState(false);
    // flag used to redirect within KB, if false, KB Website redirects to Login Page
    const [redirectToken, setRedirectToken] = useState(true);
    // flag used to direct to OAuth Page, if true OAuth page is opened, else only token is fetched
    const [redirectPage, setRedirectPage] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const [hasPermission, setHasPermission] = useState(false);
    const [loginInitiated, setLoginInitiated] = useState(false);

    const getTokenFunction = () => {
        const handleFetchUserDetails = async (token = null) => {
            setHiddenState(!!token);
            try {
                const response = await getUserDetails(token);
                const userData = response?.data?.data;
                const userRole = userData?.userRole;
                setUserDetails(userData);
                setHasPermission(INTERNAL_KB_ALLOWED_ROLES.includes(userRole));
                setShowDashboardFooter(DASHBOARD_ALLOWED_ROLES.includes(userRole));
            } catch (error) {
                setUserDetails(null);
                setHasPermission(false);
                setShowDashboardFooter(false);
                throw error;
            }
        };
    
        const handleTokenSuccess = async (token) => {
          setNoNet(false);
          setAuthToken(token);
          setState("authToken-website", token);
          getUserDetailsviaV2ProfileMe()
            .then(async (resp) => {
              const { user_id, usergroup_id } = resp?.data ?? resp;
              setAppUgId(usergroup_id);
              setAppUserId(user_id);
              if (isInternal) {
                try {
                  await handleFetchUserDetails(token);
                  setRedirectToken(true); // don't navigate to login
                  setShowLoader(false);
                } catch (error) {
                  throw error;
                }
              } else {
                setHasPermission(true);
                setShowDashboardFooter(!!token);
              }
              setLoginInitiated(true);
              setIsProfileMeDone(true);
            })
            .catch((err) => {
              console.error("cannot get user details", err);
              setIsProfileMeDone(false);
            });
        };
    
        const handleOAuth = () => {
            localStorage.clear();
            getGoogleAuthUrl(email)
                .then(gAuth => {
                    gAuth &&
                        open_popup(
                            gAuth,
                            onOAuthSuccess,
                            email,
                            setRefetchToken,
                            setRedirectPage,
                            setEmail,
                            setAuthToken,
                            setShowError,
                            setLoginInitiated
                        );
                    }
                )
                .catch(e => console.error("Error while getting Google auth:", e));
        };
    
        const handleError = async (rej) => {
            setShowToast(rej?.data?.message);
            setNoNet(false);
            setRedirectToken(false); // redirects to login page
            setShowLoader(false);
            setAuthToken(null);
            if (rej?.response?.status === 401) {
                if (isInternal) {
                    try {
                        if (redirectPage) {
                            handleOAuth();
                        }
                    } catch (error){
                        setRedirectToken(false); // redirects to login page
                    }
                } else {
                    setHasPermission(true);
                    setShowDashboardFooter(false);
                    // external kb doesnt need to have a dependency here. 
                    // so changing it to true to allow users to make api calls.
                    setIsProfileMeDone(true);
                }
            }
        };
        getToken()
            .then((res) => {
                handleTokenSuccess(res?.data?.token);
            })
            .catch(handleError);
    };
    

    useEffect(() => {
        if (refetchToken && data?.knowledgeBaseId) {
            getTokenFunction();
            setRefetchToken(false);
        }
    // disabling eslint to hide dependecy warning concerning getTokenFunction,
    // reason being that need to wrap it in a useCalback, which will result in the
    // function being reconstructed and cached multiple times as it will have a lot 
    // of dependecies itself, don't want to rework too much as it is legacy code    
    // please be mindful of dependencies while extending the code
    // as linter warnings won't be shown for missing dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refetchToken, data, setRefetchToken]);

    useEffect(() => {
        const timer = setInterval(() => {
            if (data?.knowledgeBaseId) {
                getTokenFunction();
            }
        }, 6 * 60 * 1000);
        return () => clearInterval(timer);
    // disabling eslint to hide dependecy warning concerning getTokenFunction,
    // reason being that need to wrap it in a useCalback, which will result in the
    // function being reconstructed and cached multiple times as it will have a lot 
    // of dependecies itself, don't want to rework too much as it is legacy code    
    // please be mindful of dependencies while extending the code
    // as linter warnings won't be shown for missing dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        setState("authToken-website", authToken);
    }, [authToken]);

    return (
        <AutherContext.Provider
            value={{
                setEmail: setEmail,
                email: email,
                isProfileMeDone,
                authToken: authToken,
                setAuthToken: setAuthToken,
                redirectToken: redirectToken,
                setShowError,
                showError,
                setRedirectToken: setRedirectToken,
                userSession,
                setUserSession,
                refetchToken,
                setRefetchToken,
                setShowLoader,
                setRedirectPage,
                showLoader,
                userDetails,
                hasPermission,
                loginInitiated,
                setLoginInitiated
            }}
        >
            {props.children}
        </AutherContext.Provider>
    );
}
