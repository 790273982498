import { handleGainSightEventForPageVisit } from './gainSightIntegration';
import { KB_TYPE } from './Constants/KBContext.constants';
import { Hiver_Base_Url } from './Constants/ApiRoutes';

export const handlePageVisitData = (location, isInternal) => {
    const pageVisitData = {
      pageUrl: location.href,
      pageBaseUrl: `${location.protocol}//${location.host}`,
      kbType: isInternal ? KB_TYPE.INTERNAL : KB_TYPE.EXTERNAL,
    }
    handleGainSightEventForPageVisit(pageVisitData);
}

export const isFeatureEnabled = (feature, features = {}) => {
  return features?.[feature] === true;
}

export const REDIRECT_TO_HOMEPAGE_MESSAGE = "Redirecting to Home in {{countdown}} second{{plural}}";

export const buildParamString = (params = {}) => {
  let paramString = "";
  if (Object.keys(params).length > 0) {
    paramString = '?' + new URLSearchParams(params).toString();
  }
  return paramString;
}

//Returns the value of app_env and tier(used to make bifrost call) for different envs
export const getEnv = () => {
  const reactAppEnv = process.env.REACT_APP_KB_ENV;
  const isBuddyEnv = process.env.REACT_APP_IS_BUDDY_ENV;
  if (reactAppEnv === "dev") {
    //buddy-cli env
    return {
      app_env: "dev",
      tier: "dev",
    };
  } else if (isBuddyEnv === "true") {
    //Buddy qa env
    return {
      //start index 10 because it starts like https://v2 + userxp-5 + .hiver.space so accomodating it
      app_env: Hiver_Base_Url.substring(10, Hiver_Base_Url.indexOf('.hiver.space')),
      tier: "sit",
    };
  } else {
    //production env
    return {
      app_env: "v2master",
      tier: "production",
    };
  }
}