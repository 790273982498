import { useState, useEffect } from "react";
import styled from "styled-components";
import { SearchContext } from "../index";
import { articleSearch } from "../../Service/ArticleService";
import { useSearchParams } from "react-router-dom";
import { debounce, apiWithAbort } from "../../Constants/utility";
import ButtonLoader from "../../Components/Loaders/ButtonLoader";

const LoaderWrapper = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
`;

const searchWithAbort = apiWithAbort(articleSearch);

const getSearchArticles = ( { searchTerm, product, onLoad, onSuccess } ) =>{
  const pathParams = {
    searchTerm,
    ...(product && { product })
  };
  onLoad(true);
  searchWithAbort(searchTerm, pathParams).then((result) => {
    onSuccess(result.data.data.articles);
  }).catch( console.warn ).finally(() =>{
    onLoad(false);
  });
};

const debouncedSearch = debounce(getSearchArticles, 300);

export default function ArticleProvider(props) {
  const [searchList, setSearchList] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [ loading, setLoading ] = useState(false);
  const [searchParams] = useSearchParams();
  const productParam = searchParams.get('product');

  useEffect(() => {
    setLoading(false);
    if (searchInput.length === 0) {
      setSearchList(false);
    } else if (searchInput.length > 2) {
      debouncedSearch({ searchTerm: searchInput, product: productParam, onLoad: setLoading, onSuccess: setSearchList });
    }
  }, [searchInput, productParam]);

  return (
    <SearchContext.Provider
      value={{
        searchList,
        setSearchInput,
        setSearchList,
        searchInput,
      }}
    >
      {props.children}
      {
        loading && (
          <LoaderWrapper>
            <ButtonLoader h="20px" w="20px" />
          </LoaderWrapper>
        )
      }
    </SearchContext.Provider>
  );
}
