import styled from "styled-components";
import errorIcon from "../../images/inputError.svg";
const Image_Base_Url = process.env.REACT_APP_IMAGE_BASE_URL;

export const BannerWrapper = styled.div`
  width: 100%;
  height: 342px;
  background: ${(props) =>
    `url(${Image_Base_Url + props.bannerData.bannerImage})`};
  background-color: ${(props) =>
    !props.bannerData.bannerImage ? props.bannerData?.brandColor : ""};
  background-size: cover;
  background-position: center;
`;

export const FlexWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  width: ${(props) => (props.width ? props.width : "100%")};
  flex-direction: ${(props) => props.direction};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  padding: ${(props) => (props.padding ? props.padding : "0")};
  justify-content: ${(props) => (props.justify ? props.justify : "")};
  align-items: ${(props) => (props.align ? props.align : "")};
  z-index: ${(props) => props.zIndex};
  @media (max-width: 768px) {
    display: ${(props) => (props.hide ? "none" : "")};
  }
`;

export const AbsoluteWrapper = styled.div`
  position: ${(props) => props.position};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.top};
  bottom: ${(props) => props.left};
  z-index: ${(props) => props.zIndex};
  transform: ${(props) => props.transform};
  cursor: ${(props) => props.cursor};
`;

export const BrandTittle = styled.h2`
  width: 100%;
  color: rgba(9, 39, 74, 1);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 0 0 0 0;
`;

export const FormControl = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 0 24px 0;
`;

export const FormControlWrapper = styled(FormControl)`
  padding: 8px 0;
`;

export const InputGroupWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  border: 1px solid rgba(209, 224, 241, 1);
  line-height: 28px;
  border-radius: 4px;
  margin: ${(props) => (props.margin ? props.margin : "0")};

  &:focus-within {
    border: 1px solid rgba(20, 115, 230, 1);
  }

  &.error {
    border: 1px solid rgba(205, 55, 70, 1);
  }
`;

export const Label = styled.label`
  width: ${(props) => (props.width ? props.width : "100%")};
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.138889px;
  color: #313c4b;
  margin-bottom: 8px;

  &.articleLabel {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #313c4b;
    cursor: pointer;
    margin: 0;
    padding: 0 25px 0 0;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    // &:hover {
    //   overflow: auto;
    //   text-overflow: initial;
    // }

    @media screen and (max-width: 500px) {
      font-size: 14px;
      line-height: 21px;
    }
  }

  &.headerLabel {
    color: rgba(49, 60, 75, 1);
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    line-height: 24px;
    &:hover{
      text-decoration: underline;
    }
  }

  &.headerLabelSelect {
    color: rgba(49, 60, 75, 1);
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    line-height: 24px;
  }

  &.bannerText {
    font-size: 34px;
    font-style: normal;
    font-weight: 500;
    line-height: 51px;
    letter-spacing: -0.320374071598053px;
    text-align: center;
    color: ${(props) => props.bannerColor};
    padding: ${(props) => props.padding || "0 0 20px 0"};
    margin: 0;

    @media (max-width: 500px) {
      font-weight: 500;
      font-size: 28px;
      line-height: 42px;
    }
  }

  &.label-info {
    color: rgba(40, 71, 106, 1);
    font-weight: 400;
    font-size: 13px;
  }
  &.error {
    color: rgba(205, 55, 70, 1);
    padding-left: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 28px;
  }

  &.maxlengthLabel {
    width: auto;
    position: absolute;
    top: 10px;
    right: 14px;
  }

  &.textAreaMaxlengthLabel {
    width: auto;
    position: absolute;
    top: 124px;
    right: 14px;
  }

  &.maxlengthLabelError {
    color: rgba(205, 55, 70, 1);
  }
  &.tooltipLabel {
    padding: 12px 0 0 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }

  transition: font-size 1s, line-height 1s, color 1s;
`;

export const Input = styled.input`
  font-family: "Poppins";
  width: ${(props) => (props.width ? props.width : "100%")};
  font-size: 14px;
  padding: ${(props) => (props.padding ? props.padding : "9px 50px 9px 12px")};
  color: rgba(9, 39, 74, 1);
  background: transparent;
  border: ${(props) =>
    props.border ? props.border : "1px solid rgba(209, 224, 241, 1)"};
  border-left: ${(props) => props.borderLeft};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "4px"};
  line-height: 28px;
  font-weight: 400;
  z-index: 1;
  outline: none !important;
  ${(props) =>
    props.focusBorder
      ? ""
      : `&:focus {
                border: 1px solid rgba(20, 115, 230, 1);
            }`}

  &.error {
    border: 1px solid rgba(205, 55, 70, 1);
  }
  &.errorWithIcon {
    border: 1px solid rgba(205, 55, 70, 1);
    background: url(${errorIcon}) no-repeat;
    background-position: 96% 12px;
  }
  :placeholder-shown {
    color: rgba(78, 99, 130, 1);
  }
  ::-moz-placeholder {
    color: rgba(78, 99, 130, 1);
  }
  :-ms-input-placeholder {
    color: rgba(78, 99, 130, 1);
  }
  ::-webkit-input-placeholder {
    color: rgba(78, 99, 130, 1);
  }
`;

export const TextArea = styled.textarea`
  font-family: inherit;
  width: ${(props) => (props.width ? props.width : "100%")};
  font-size: 13px;
  padding: ${(props) => (props.padding ? props.padding : "8px 50px 8px 12px")};
  color: rgba(9, 39, 74, 1);
  background: transparent;
  border: ${(props) =>
    props.border ? props.border : "1px solid rgba(209, 224, 241, 1)"};
  border-left: 1px solid rgba(209, 224, 241, 1);
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "4px"};
  line-height: 28px;
  font-weight: 400;
  z-index: 1;
  outline: none !important;
  resize: none;

  &:focus {
    border: ${(props) =>
      props.border ? props.border : "1px solid rgba(20, 115, 230, 1)"};
  }

  &.error {
    border: 1px solid rgba(205, 55, 70, 1);
  }
  &.errorWithIcon {
    border: 1px solid rgba(205, 55, 70, 1);
    background: url(${errorIcon}) no-repeat;
    background-position: 98% 12px;
  }
  :placeholder-shown {
    color: rgba(78, 99, 130, 1);
  }
  ::-moz-placeholder {
    color: rgba(78, 99, 130, 1);
  }
  :-ms-input-placeholder {
    color: rgba(78, 99, 130, 1);
  }
  ::-webkit-input-placeholder {
    color: rgba(78, 99, 130, 1);
  }
`;

export const ColorInput = styled.input.attrs({ type: "color" })`
  position: absolute;
  height: 100%;
  font-size: 13px;
  padding: 6px 12px;
  color: rgba(9, 39, 74, 1);
  background: transparent;
  border: 0;
  border-radius: 4px;
  line-height: 20px;
  font-weight: 400;
  outline: none !important;
  z-index: 5;
  //border-right: 1px solid rgba(209,224,241,1);
  cursor: pointer;
`;

export const StyledButton = styled.button`
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  font-family: Poppins;
  padding: ${(props) => (props.submit === "true" ? "10px 45px" : "6px 17px")};
  border-radius: 4px;
  outline: none !important;
  margin-top: ${(props) => (props.submit === "true" ? "30px" : "")};
  cursor: pointer;

  background-color: ${(props) =>
    props.color
      ? props.color
      : props.primary
      ? "rgba(20, 115, 230, 1)"
      : props.secondary
      ? "white"
      : props.delete
      ? "rgba(205, 55, 70, 1)"
      : "rgba(255, 255, 255, 1)"};
  border: 1px solid
    ${(props) => (props.secondary ? "rgba(20, 115, 230, 1)" : "white")};
  color: ${(props) =>
    props.textColor
      ? props.textColor
      : props.primary
      ? "rgba(255, 255, 255, 1)"
      : props.secondary
      ? "rgba(20, 115, 230, 1)"
      : props.delete
      ? "rgba(255, 255, 255, 1)"
      : "rgba(40, 71, 106, 1)"};

  &:hover {
    background-color: ${(props) =>
      props.color
        ? props.color
        : props.primary
        ? "rgba(64, 146, 245, 1)"
        : props.secondary
        ? "rgba(242, 246, 255, 1)"
        : props.delete
        ? "rgba(217, 156, 162, 1)"
        : "rgba(242, 246, 255, 1)"};
  }

  &.disable {
    background-color: ${(props) =>
      props.primary ? "#F4F4F4" : props.secondary ? "#F4F4F4" : "#F4F4F4"};
    border: 1px solid
      ${(props) =>
        props.secondary ? "rgba(214, 224, 235, 1)" : "rgba(255, 255, 255, 1)"};
    color: ${(props) =>
      props.primary ? "#8F97B2" : props.secondary ? "#8F97B2" : "#8F97B2"};
  }
  &.disableInSubmit {
    background: #f4f4f4;
    color: #8f97b2;
  }
  @media (max-width: 425px) {
    width: 100%;
  }
`;

export const IconButton = styled.div`
  position: relative;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  padding: 6px 23px;
  border-radius: ${(props) => props.borderRadius};
  outline: none !important;
  border: 1px solid rgba(209, 224, 241, 1);
  border-left: ${(props) => props.borderLeft};
  background: url(${(props) => props.Icon}) no-repeat;
  background-position: 50% 50%;
  cursor: pointer;
`;

export const TooltipBox = styled.div`
  display: flex;
  position: absolute;
  top: calc(100% + 10px);
  padding: 5px 10px;
  width: max-content;
  left: -15px;
  visibility: hidden;
  color: transparent;
  background-color: transparent;
  border-radius: 4px;
  z-index: 100000;
  max-width: 200px;
  transition: color 0.5s, background-color 0.5s, width 0.5s,
    padding 0.5s ease-in-out;
  &:before {
    content: "";
    width: 0;
    height: 0;
    left: 40px;
    top: -5px;
    position: absolute;
    border: 5px solid transparent;
    transform: rotate(135deg);
    transition: border 0.3s ease-in-out;
  }
`;

export const TooltipCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  & ${(props) => props.Component}:hover + ${TooltipBox} {
    visibility: visible;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    &:before {
      border-color: transparent transparent rgba(0, 0, 0, 0.8)
        rgba(0, 0, 0, 0.8);
    }
  }
`;

export const DropDownBox = styled.div`
  position: absolute;
  min-width: 180px;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 25%) 0 0 10px 0px;
  z-index: 100;
  background: rgba(255, 255, 255, 1);
  outline: none !important;
`;

export const DropDownList = styled.ul`
  padding: 5px 0;
  list-style: none;
  width: 100%;
  margin: 0;
`;

export const DropdownRow = styled.li`
  margin: 0;
  padding: 10px 15px;
  width: auto;
  height: auto;
  &: hover {
    background: rgba(219, 235, 255, 1);
  }
  &.selected {
    background: rgba(219, 235, 255, 1);
  }
`;

export const UserImage = styled.img`
    width: 40px;
    height 40px;
    border-radius:50%;
`;

export const ButtonIcon = styled.img`
  height: 18px;
  width: 18px;
  margin: 5px 10px -5px 0;
`;

export const Icon = styled.img`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  margin: 5px 10px -5px 0;
`;

export const InputWrapper = styled.div`
  box-sizing: border-box;
  width: ${(props) => (props.width ? props.width : "100%")};
  display: flex;
  flex-direction: ${(props) => props.direction};
  position: ${(props) => props.position};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  padding: ${(props) => (props.padding ? props.padding : "0")};
  justify-content: ${(props) => (props.justify ? props.justify : "")};
  align-items: ${(props) => (props.align ? props.align : "")};

  top: ${(props) => props.top};
  left: ${(props) => props.left};
  z-index: ${(props) => props.zIndex};
`;

export const TooltipIcon = (props) => {
  return (
    <TooltipCard>
      <Icon
        width={props.width}
        height={props.height}
        alt="dashboardSettingLink"
        src={props.image}
      />
      <TooltipBox>
        <Label className="tooltipLabel">{props.content}</Label>
      </TooltipBox>
    </TooltipCard>
  );
};

export const CustomizedNavigationButton = styled.div`
  padding: 4px 20px;
  cursor: pointer;
  color: ${(props) => props.bannerData?.bannerTextColor};
  background: ${(props) => props.bannerData?.brandColor};
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.2px;
  border-radius: 4px;
  min-width: 64px;
`;