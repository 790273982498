export const Base_Url = process.env.REACT_APP_API_BASE_URL;
export const Admin_Base_URL = process.env.REACT_APP_ADMIN_BASE_URL;
export const Hiver_Base_Url = process.env.REACT_APP_HIVER_API_BASE_URL;
export const PUBLIC_ENDPOINT = 'public/';
export const NEW_AUTH_BASE_PATH = 'v1/';
export const Hiver_New_Auth_Public_Base_Url = process.env.REACT_APP_NEW_AUTH_BASEURL + PUBLIC_ENDPOINT + NEW_AUTH_BASE_PATH;
export const Hiver_New_Auth_Base_Url = process.env.REACT_APP_NEW_AUTH_BASEURL + NEW_AUTH_BASE_PATH;
export const App_Language = "EN";
export const App_Version = "1.0.0";

export const apiRoutes = {
  submitQuery: "submit-query",
  categories: "category",
  getArticlesInCategory: "articles",
  getParticulatArticle: "article",
  articleSearch: "search",
  hiverGetToken: "get-token",
  OAuthComplete: "gx/ext/login/completeLogin",
  newHiverLoginInit: "gx/ext/login/init",
  availableFeatures: "available-features",
  //To get User details via v2
  getUserDetails: "api/auth/profile-me",
  getUserDetailsViaKb: "user-details"
};