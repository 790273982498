import { Bifrost } from '@hiver/bifrost-js';
import { getAppConfig, getAppUgId, getAppUserId, getKbBaseUrl } from './api';


const bifrost = new Bifrost(1);

const HiverAuthCall = ({ configHiver, withCredentials, rootUrl = '' }) => {
  const makeNewAuthAxiosCall = async (inputs) => {
    const { appEnv, tier } = getAppConfig();
    const userId = getAppUserId();
    const usergroupId = getAppUgId();
    const { url, method, getToken = true, root_url = rootUrl, noToken = withCredentials } = inputs;
    const serviceRootUrl =
      (root_url ?? getKbBaseUrl()) + (inputs?.prefix || "");

    const requestParams = {
      root_url: serviceRootUrl,
      url,
      method,
      withCredentials: noToken,
      appEnv,
      tier,
      headers: inputs?.headers,
      csrfToken: inputs?.csrfToken,
      onSuccess: inputs?.onSuccess,
      onFail: inputs?.onFail,
      data: inputs?.data,
      params: inputs?.params,
      timeout: inputs?.timeout,
      formData: inputs?.formData,
      userId: Number(userId),
      usergroupId: Number(usergroupId)
    };
    try {
      console.log(
        '--- requestParams - ',
        requestParams,
        ' --- configHiver - ',
        configHiver,
        ' --- isHiverApiDomain - ',
        true
      );
      const response = await bifrost.hiverAxios(requestParams, { ...configHiver, ...({ getToken }) });
      return response;
    } catch (error) {
      console.error('API call failed with error: ', error);
      const errorObj = {
        ...error,
        ...(error?.response && { ...error?.response }),
        ...(error?.request && { ...error.request }),
      };
      throw errorObj;
    }
  };

  return makeNewAuthAxiosCall;
};

const HiverKbBifrost = HiverAuthCall({
  configHiver: {
    fetchCsrfToken: () => '',
    isHiverApiDomain: true
  },
  withCredentials: true
});

export { bifrost, HiverAuthCall, HiverKbBifrost };
