import { useState, useEffect, useContext } from "react";
import { ArticleContext, CategoryContext, KBContext, AutherContext } from "../index";
import {
  getArticles,
  getParticularArticle,
} from "../../Service/ArticleService";
import { useSearchParams } from "react-router-dom";

export default function ArticleProvider(props) {
  const { categoryData } = useContext(CategoryContext);
  const { userDetails } = useContext(AutherContext);
  const { setCustomMeta, setArticleTitle, data: { isInternal } } = useContext(KBContext);
  const [searchParams] = useSearchParams();
  const productParam = searchParams.get('product');

  const [categorySlug, setCategorySlug] = useState(false);
  const [articleSlug, setArticleSlug] = useState(false);

  const [articleData, setArticleData] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(true);
  // Below condition because we need to wait for these data before fetching articles.
  // Otherwise token wouldnt have been generated.
  const isInternalReady = typeof isInternal === 'boolean';
  const isUserDetailsFinished = isInternal ? !!userDetails : true;
  const shouldHitApi = isInternalReady ? isUserDetailsFinished : false;

  useEffect(() => {
    if (categorySlug && shouldHitApi) {
      const pathParams = {
        sortBy: "rank",
        sort: "DESC",
        ...(productParam && { product: productParam })
      };
      setArticleData([]);
      getArticles(categorySlug, pathParams)
        .then((res) => {
          if (res?.data?.data?.articles?.length > 0) {
            setArticleData(res?.data?.data?.articles);
          } else {
            setArticleData([]);
          }
        })
        .catch((rej) => {
          setArticleData(false);
        });
    }
  }, [categoryData, categorySlug, productParam, shouldHitApi]);

  useEffect(() => {
    if (articleSlug && shouldHitApi) {
      const pathParams = {
        ...(productParam && { product: productParam })
      };
      getParticularArticle(articleSlug, pathParams)
        .then((res) => {
          setCustomMeta(res?.data?.data?.articleMeta);
          setSelectedArticle(res?.data?.data);
          const { title } = res?.data?.data || {};
          title && setArticleTitle(title);
        })
        .catch((rej) => {
          setSelectedArticle(false);
        });
      } else {
        setCustomMeta(null);
      }
    }, [articleSlug, productParam, shouldHitApi]);
    
  return (
    <ArticleContext.Provider
      value={{
        articleData: articleData,
        setArticleData: setArticleData,
        setSelectedArticle,
        selectedArticle,
        categorySlug,
        setCategorySlug,
        articleSlug,
        setArticleSlug,
      }}
    >
      {props.children}
    </ArticleContext.Provider>
  );
}
