const config = {
    userId: '',
    usergroupId: '',
    baseUrl: '',
    token: '',
    appEnv: '',
    tier: ''
  };
  
  export const setAppConfig = (baseUrl, appEnv, tier) => {
    config.baseUrl = baseUrl;
    config.appEnv = appEnv;
    config.tier = tier;
  };
  
  export const setAppUserId = (id) => {
    config.userId = id;
  };
  
  export const getAppUserId = () => {
    return config.userId;
  };
  
  export const setAppUgId = (id) => {
    config.usergroupId = id;
  };
  
  export const getAppUgId = () => {
    return config.usergroupId;
  };
  
  export const getAppConfig = () => {
    return {
      appEnv: config.appEnv,
      tier: config.tier,
      baseUrl: config.baseUrl
    };
  };
  
  export const getKbBaseUrl = () => {
    if (!config.baseUrl) {
      throw new Error('Base url is not setup. please call the setAppConfig function with the appropriate value');
    }
    return config.baseUrl;
  };
  
  export const getApiUrl = (url) => {
    const { isV2, apiPath } = url(config);
    return isV2 ? `${apiPath}?usersession=${config.userId}` : apiPath;
  };