import { Base_Url, apiRoutes, Admin_Base_URL } from "../Constants/ApiRoutes";
import axios from "axios";
import { getState } from "../Constants/LocalStorage";

const axiosInstance = axios.create({
  credentials: "include",
  withCredentials: true,
  baseURL: Admin_Base_URL,
});

const axiosInstanceLogin = axios.create({
  baseURL: Base_Url,
});

axiosInstanceLogin.interceptors.request.use(
  function (config) {
    const token = getState("authToken-website");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const getUserDetailsviaV2ProfileMe = () => {
  return axiosInstance.get(apiRoutes.getUserDetails);
};

export const completeOAuth = function (authToken, email) {
  const payload = `email=${email}&token=${authToken}`;
  return axiosInstance.post(apiRoutes.OAuthComplete, payload , {
    headers: { 
      "Content-Type": 'application/x-www-form-urlencoded; charset=UTF-8'
    },
  });
};

export const newHiverLoginInit = (payload) => {
  return axiosInstance.post(apiRoutes.newHiverLoginInit, payload, {});
}
