import { useState, useEffect, useContext, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { KBContext, ToastContext } from "../index";

import { getRequest, getAvailableFeatures } from "../../apiRequest";
import { MetaTags } from "../../Components/MetaTags";

import { FEATURE_FLAG_NEW_AUTH } from "../../Constants/KBContext.constants";
import { codeSnippet } from "../../Constants/utility";

export default function KBProvider(props) {
  const { setNoNet, setShowToast } = useContext(ToastContext);
  const [data, setData] = useState(true);
  const [customMeta, setCustomMeta] = useState(null);
  const [showCategoryLoader, setShowCategoryLoader] = useState(false);
  const [hiddenState, setHiddenState] = useState(false);
  const [showDashboardFooter, setShowDashboardFooter] = useState(false);
  const [availableFeatures, setAvailableFeatures] = useState({});
  const [isNewAuthFlow, setIsNewAuthFlow] = useState(false);
  const [articleTitle, setArticleTitle] = useState(data?.title);
  const [searchParams] = useSearchParams();
  const productParam = searchParams.get('product');

  useEffect(() => {
    setShowCategoryLoader(true);
    const pathParams = {
      ...(productParam && { product: productParam })
    };
    getRequest("knowledgebase", null, pathParams)
      .then((res) => {
        setData(res?.data?.data?.knowledgeBaseInfo);
        setArticleTitle(res?.data?.data?.knowledgeBaseInfo?.title);
        setShowCategoryLoader(false);
        codeSnippet(res?.data?.data?.knowledgeBaseInfo?.codeSnippet);
      })
      .catch(() => {
        setData(false);
        setShowCategoryLoader(false);
      });
  }, [productParam]);

  useEffect(() => {
    if (typeof data?.isInternal === 'boolean') {
      window.grexit = {
        ...window?.grexit,
        isInternal: data.isInternal,
      };
    }
  }, [data]);

  useEffect(() => {
    const timer = setInterval(() => {
      setShowCategoryLoader(true);
      const pathParams = {
        ...(productParam && { product: productParam })
      };
      getRequest("knowledgebase", null, pathParams)
        .then((res) => {
          setData(res?.data?.data?.knowledgeBaseInfo);
          setArticleTitle(res?.data?.data?.knowledgeBaseInfo?.title);
          setShowCategoryLoader(false);
          codeSnippet(res?.data?.data?.knowledgeBaseInfo?.codeSnippet);
        })
        .catch(() => {
          setData(false);
          setShowCategoryLoader(false);
        });
    }, 60 * 60 * 1000);
    return () => clearInterval(timer);
  }, [productParam]);

  useEffect(() => {
    if (!hiddenState) {
      setTimeout(() => {
        setHiddenState(true);
      }, 10000);
    }
  }, [hiddenState]);

  const fetchAvailableFeatures = useCallback(
    () => {
      const pathParams = {
        ...(productParam && { product: productParam })
      };
      getAvailableFeatures(pathParams)
        .then((response) => {
          const {
            data: { availableFeatures: availableFtrs = {} },
          } = response?.data || {};
          const newAuthEnabled = availableFtrs[FEATURE_FLAG_NEW_AUTH];
          window.grexit = {
            ...window?.grexit,
            isNewAuthFlow: newAuthEnabled,
          };
          setAvailableFeatures(availableFtrs);
          setIsNewAuthFlow(newAuthEnabled);
        })
        .catch((rej) => {
          if (rej.message === "Network Error") {
            setNoNet(true);
          } else {
            setShowToast("Unable to fetch features for the account.");
          }
          console.error(rej);
        });
    },
    [setNoNet, setShowToast, setAvailableFeatures, productParam]
  );

  useEffect(() => {
    fetchAvailableFeatures();
  }, [fetchAvailableFeatures]);

  return (
    <KBContext.Provider
      value={{
        data,
        setData,
        hiddenState,
        showCategoryLoader,
        setHiddenState,
        showDashboardFooter,
        setShowDashboardFooter,
        availableFeatures,
        customMeta,
        setCustomMeta,
        setArticleTitle,
        isNewAuthFlow
      }}
    >
      <MetaTags
        title={articleTitle}
        favIcon={data?.favicon}
        description={data?.description}
        customMeta={customMeta}
        kbTitle={data?.title}
      />
      {props.children}
    </KBContext.Provider>
  );
}
